//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import moment from 'moment';
import { PatientService } from '@/services/patient.service';
export default {
  props: {
    rowData: {
      type: Object,

      default() {
        return {};
      }

    }
  },
  watch: {
    async showDialog() {
      if (!this.showDialog) {
        this.alert.show = false;
        this.firstname = '';
        this.lastname = '';
        this.birthday = '';
        this.phoneno = null;
        this.email = '';
        this.shippingaddress = {
          address1: '',
          address2: '',
          zipcode: '',
          city: ''
        };
        this.billingaddress = {
          address1: '',
          address2: '',
          zipcode: '',
          city: ''
        };
        this.editAddressMode = false;
        this.sameaddress = true;
        this.$emit('clear-data');
      } else {
        this.loading = true;
        const res = await PatientService.getPatient(this.rowData.id); // Set data

        if (res.data.firstname) this.firstname = res.data.firstname;
        if (res.data.lastname) this.lastname = res.data.lastname;
        if (res.data.birthday) this.birthday = moment(res.data.birthday).format('MM-DD-YYYY');
        if (res.data.phoneno) this.phoneno = res.data.phoneno.replace('+1', '');
        if (res.data.email) this.email = res.data.email;
        if (res.data.email) this.originalEmail = res.data.email;

        if (res.data.shippingaddress) {
          this.shippingaddress = res.data.shippingaddress;
        }

        if (!res.data.sameaddress) {
          this.sameaddress = false;
        } else {
          this.sameaddress = res.data.sameaddress;
        }

        if (res.data.billingaddress) {
          this.billingaddress = res.data.billingaddress;
        }

        this.loading = false;
      }
    }

  },

  data() {
    return {
      showDialog: false,
      alert: {
        show: false,
        message: ''
      },
      // Form date
      firstname: '',
      lastname: '',
      birthday: '',
      phoneno: null,
      originalEmail: '',
      email: '',
      shippingaddress: {
        address1: '',
        address2: '',
        zipcode: '',
        city: ''
      },
      billingaddress: {
        address1: '',
        address2: '',
        zipcode: '',
        city: ''
      },
      editAddressMode: false,
      sameaddress: true,
      buttonDisabled: false,
      loading: false
    };
  },

  methods: {
    toggleDialog() {
      this.showDialog = !this.showDialog;
    },

    showAlert(message) {
      this.alert.show = true;
      this.alert.message = message;
    },

    async submitChanges() {
      try {
        this.buttonDisabled = true;
        this.alert.show = false; // Email

        if (this.email != this.originalEmail) {
          await PatientService.updatePatientEmail(this.rowData.id, {
            email: this.email
          });
        }

        const age = moment().diff(moment(this.birthday, 'MM-DD-YYYY'), 'years');

        if (age > 60 || age < 18) {
          throw 'Patient must be 18 and above, and 60 years old and below.';
        }

        if (moment(this.birthday, 'MM-DD-YYYY') == 'Invalid date' || this.birthday.length != 10) {
          throw 'Invalid date supplied, please format as MM-DD-YYYY';
        } // Rest of the data


        let userData = {
          firstname: this.firstname,
          lastname: this.lastname,
          phoneno: this.phoneno,
          birthday: moment(this.birthday, 'MM-DD-YYYY').format('YYYY-MM-DD')
        };
        const res = await PatientService.updatePatientData(this.rowData.id, userData);

        if (res) {
          this.buttonDisabled = false;
          this.$emit('refresh-self');
          this.toggleDialog();
        }
      } catch (err) {
        this.buttonDisabled = false;
        this.showAlert(err);
      }
    }

  }
};